import React, { useState, ReactNode, useEffect } from 'react';
import { Alert, AlertProps } from '@material-ui/lab';
import {
  makeStyles,
  Theme,
  createStyles,
  IconButton,
  Collapse,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import colors from '../../themes/main-colors';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: theme.spacing(2),
      [theme.breakpoints.down('sm')]: {
        margin: theme.spacing(2, 0),
        borderRadius: 0,
      },
    },
    action: {
      alignItems: 'start',
    },
    bodyContainer: {
      '& :first-child': {
        marginTop: 0,
      },
      '& .RichText p': {
        fontSize: theme.spacing(1.75),
      },
      '& .RichText a, & .RichText button': {
        color: colors.white,
      },
    },
    standardSuccess: {
      backgroundColor: colors.shoreGreen,
    },
    outlinedSuccess: {
      backgroundColor: colors.shoreGreen,
    },
    filledSuccess: {
      backgroundColor: colors.shoreGreen,
    },
    standardWarning: {
      backgroundColor: colors.warning,
    },
    outlinedWarning: {
      backgroundColor: colors.warning,
    },
    filledWarning: {
      backgroundColor: colors.warning,
    },
    standardError: {
      backgroundColor: colors.roseRed,
    },
    outlinedError: {
      backgroundColor: colors.roseRed,
    },
    filledError: {
      backgroundColor: colors.roseRed,
    },
    standardInfo: {
      backgroundColor: colors.info,
    },
    outlinedInfo: {
      backgroundColor: colors.info,
    },
    filledInfo: {
      backgroundColor: colors.info,
    },
  }),
);

const BroadcastCommunicationBanner = ({
  severity,
  messageBody,
  isEaseIn = false,
  ...props
}: AlertProps & { messageBody?: ReactNode; isEaseIn?: boolean }) => {
  const classes = useStyles();
  const { bodyContainer, ...rest } = classes;
  const [open, setOpen] = useState(!isEaseIn);

  useEffect(() => {
    if (isEaseIn) {
      setOpen(true);
    }
  }, []);

  return (
    <Collapse in={open} timeout={{ enter: 700 }}>
      <Alert
        data-testid="broadcast-message-alert"
        classes={rest}
        severity={severity}
        variant="filled"
        {...props}
        action={
          <IconButton
            aria-label="close"
            color="inherit"
            size="small"
            data-testid="broadcast-message-close-action"
            className="close-icon"
            onClick={() => {
              setOpen(false);
            }}
          >
            <Close />
          </IconButton>
        }
      >
        {/* <AlertTitle data-testid="broadcast-message-title">
          {messageTitle}
        </AlertTitle> */}
        <div className={bodyContainer}>{messageBody}</div>
      </Alert>
    </Collapse>
  );
};

export default BroadcastCommunicationBanner;
