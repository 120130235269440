import React from 'react';
import {
  makeStyles,
  Theme,
  createStyles,
  useTheme,
} from '@material-ui/core/styles';
import Tabs, { TabProps } from '../tabs/Tabs';
import colors from '../../themes/main-colors';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import PGEAccordion from '../accordion/';
import { Field } from '../../contentful.d';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      borderRadius: 5,
    },
    headerTitle: {
      fontSize: theme.spacing(4.5),
      fontWeight: theme.spacing(12.5),
      color: colors.noirBlur,
      paddingBottom: theme.spacing(1.25),
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.spacing(3),
        paddingBottom: theme.spacing(1.25),
      },
    },
    descText: {
      fontSize: theme.spacing(2.5),
      color: colors.noirBlur,
      paddingBottom: theme.spacing(3.75),
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.spacing(2),
        paddingBottom: theme.spacing(2.5),
      },
    },
    image: {
      width: '100%',
      height: 275,
      objectFit: 'cover',
      borderRadius: theme.spacing(0.625, 0.625, 0, 0),
      display: 'block',
      objectPosition: ({ imagePosition }: TabsetProps) => imagePosition,
      [theme.breakpoints.down('sm')]: {
        height: 135,
      },
    },
  }),
);

interface TabsetProps {
  tabData: TabProps;
  header?: string;
  tabsetImage?: any;
  intro?: string;
  readonly imagePosition?: Field['Position'];
}

export default (props: TabsetProps) => {
  const classes = useStyles(props);
  const theme = useTheme();

  const matches = useMediaQuery(theme.breakpoints.down('sm'));
  const length: number = props?.tabData?.tabs?.length;

  let component: React.ReactNode = <></>;
  if (matches) {
    if (length < 3) {
      component = <Tabs {...props.tabData} />;
    } else {
      const accordionItems: any = props?.tabData?.tabs?.map((item: any) => {
        return {
          tabTitle: item?.label,
          tabBody: item?.data,
          tabTitleIcon: item?.icon,
        };
      });
      component = <PGEAccordion accordionItems={accordionItems} />;
    }
  } else {
    component = <Tabs {...props.tabData} />;
  }
  return (
    <div className={classes.root}>
      {props.tabsetImage && (
        <img
          className={classes.image}
          src={props.tabsetImage?.file?.url}
          alt={props.header}
        />
      )}
      {props.header && (
        <div className={classes.headerTitle}>{props.header}</div>
      )}
      {props.intro && <div className={classes.descText}>{props.intro}</div>}
      {component}
    </div>
  );
};
