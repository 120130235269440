import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import RelatedList from './RelatedList';
import colors from '../../themes/main-colors';
import { Shadow } from '../../constants/shadow';

export interface RelatedProps {
  entryName?: string;
  relatedLinksHeader?: string;
  relatedLinksIntroCopy?: JSX.Element;
  headerIntroAlignment?: boolean;
  referenceLinks?: any;
  distributeAcrossColumns?: string;
  legacySupplementalLinks?: JSX.Element;
}
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: colors.lightBlue1,
      borderRadius: theme.typography.pxToRem(5),
      padding: theme.spacing(3.75, 5),
      boxShadow: Shadow.ONE,
      '& .MuiTypography-h1': {
        marginBottom: theme.spacing(2),
      },
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(2.5),
      },
      '& ul > li > p.pge-contentful-body > a': {
        color: colors.sparkBlue,
      },
    },
    heading: {
      fontSize: theme.spacing(4.5),
      fontWeight: 'bold',
      fontFamily: 'Forma-DJR-Display',
      letterSpacing:'0.720px',
      lineHeight: theme.typography.pxToRem(40),
      marginBottom: theme.spacing(1.25),
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.spacing(3),
        lineHeight: theme.typography.pxToRem(28),
      },
    },
    pbLg: {
      [theme.breakpoints.up('sm')]: {
        paddingBottom: theme.typography.pxToRem(40),
      },
      paddingBottom: theme.typography.pxToRem(30),
    },

    pbSm: {
      [theme.breakpoints.up('sm')]: {
        paddingBottom: theme.typography.pxToRem(25),
      },
      paddingBottom: theme.typography.pxToRem(16),
    },
    pbMd: {
      [theme.breakpoints.up('sm')]: {
        paddingBottom: theme.typography.pxToRem(30),
      },
      paddingBottom: theme.typography.pxToRem(20),
      borderRadius: theme.spacing(0.625),
    },
  }),
);

const Related = (props: RelatedProps): JSX.Element => {
  const classes = useStyles();
  const {
    relatedLinksHeader,
    relatedLinksIntroCopy,
    headerIntroAlignment,
    referenceLinks,
    distributeAcrossColumns,
    legacySupplementalLinks,
  } = props;

  let related;

  const columnCount = distributeAcrossColumns
    ? parseInt(distributeAcrossColumns)
    : 2;
  if (referenceLinks) {
    const columns: Array<JSX.Element> = [];
    for (const property of referenceLinks) {
      columns.push(<RelatedList list={property} />);
    }
    const grids = (
      <React.Fragment>
        {headerIntroAlignment === false && (
          <Grid item xs={12} md={6}>
            {relatedLinksHeader && (
              <Typography className={classes.heading}>
                {relatedLinksHeader}
              </Typography>
            )}
            {relatedLinksIntroCopy && <>{relatedLinksIntroCopy}</>}
          </Grid>
        )}
        <Grid item xs={12} md={headerIntroAlignment === false ? 6 : 12}>
          <Grid container>
            {columns.map((item, index) => (
              <Grid
                item
                xs={12}
                md={columnCount === 3 ? 4 : columnCount === 2 ? 6 : 12}
                key={index}
              >
                {item}
              </Grid>
            ))}
          </Grid>
        </Grid>
      </React.Fragment>
    );
    related = (
      <>
        {headerIntroAlignment === true && (
          <React.Fragment>
            {relatedLinksHeader && (
              <Typography className={classes.heading}>
                {relatedLinksHeader}
              </Typography>
            )}
            {relatedLinksIntroCopy && <>{relatedLinksIntroCopy}</>}
          </React.Fragment>
        )}
        <Grid container>{grids}</Grid>
      </>
    );
  } else {
    related = (
      <>
        {relatedLinksHeader && (
          <Typography className={classes.heading}>
            {relatedLinksHeader}
          </Typography>
        )}
        {relatedLinksIntroCopy && <>{relatedLinksIntroCopy}</>}
        {legacySupplementalLinks}
      </>
    );
  }

  return <div className={classes.root}>{related}</div>;
};

export default Related;
