import React, { FC } from 'react';
import Box from '@material-ui/core/Box';
import Helmet from 'react-helmet';
import { SearchProvider } from '@elastic/react-search-ui';
import searchConfig from '../config/searchConfig';
import config from '../config';
import { startPostMessageService } from '../lib/postMessageService';
import { postGTMAttributes } from '../lib/gtmService';
import { globalHistory } from '@reach/router';
import { NotificationsContextProvider } from '../providers/NotificationsProvider';
import CssBaseline from '@material-ui/core/CssBaseline';
import TransitionProvider from '../providers/TransitionProvider';
import PromotionProvider from '../providers/PromotionProvider';
import { ThemeProvider } from '@material-ui/core';
import theme from '../themes/theme';
import TranslationLoader from '../components/translation-loader/TranslationLoader';
import ErrorBoundary from '../components/error-boundary/ErrorBoundary';
import Backdrop from '../components/backdrop';
import BroadcastCommunication from '../components/BroadcastCommunication';
import { Navigation } from '../components/navigation/Navigation';
import PgeFooter from '../components/navigation/pge-footer';
import Notifications from '../components/notifications';
import SessionTimeout from '../components/SessionTimeout';
import PathProvider from '../providers/PathProvider';
import ROUTES from '../routes';
import { AccountsContextProvider } from '../providers/AccountsProvider';
import AccountDisconnectedBanner from '../components/account-disconnected-banner/AccountDisconnectedBanner';
import { LanguagePreferenceAlert } from '../components/language-preference-alert/LanguagePreferenceAlert';
import { EVChargerFormStateProvider } from '../providers/EVChargerFormStateProvider';
import { RebateOnlyFormStateProvider } from '../providers/RebateOnlyFormStateProvider';

startPostMessageService();

globalHistory.listen(({ location }) => {
  postGTMAttributes(location.pathname);
});

interface Props {
  readonly element: any;
  readonly props: Readonly<{
    pageContext: Record<string, any>;
    pageResources: Record<string, any>;
    location: Record<string, any>;
  }>;
}

const wrapPageElement: FC<Props> = ({
  element,
  props: { pageContext, pageResources, location },
}) => {
  const isBrowser = !(typeof window === 'undefined');
  const pathname = location.pathname.replace(/\/$/, '');

  const isOutagesMobileApp =
    isBrowser &&
    window.location.pathname.replace(/\/$/, '') === ROUTES.OUTAGES_MOBILE_APP;
  const isPGEPLUSGetStarted =
    isBrowser &&
    window.location.pathname.replace(/\/$/, '') === ROUTES.PGE_PLUS_GET_STARTED;

  const isFullSurveyPage =
    isBrowser &&
    window.location.pathname.replace(/\/$/, '') === ROUTES.HOME_ENERGY_ANALYSIS;

  const isPGEServiceAreaMap = pathname === ROUTES.PGE_SERVICE_AREA_MAP;

  const renderPageComponents = () => (
    <Box
      id={'full-height-shim'}
      display={'flex'}
      flexDirection={'column'}
      minHeight="100vh"
    >
      {!isOutagesMobileApp && !isPGEServiceAreaMap && !isPGEPLUSGetStarted && (
        <>
          <Box id="global-messages-container">
            <BroadcastCommunication />
          </Box>
          <Box id="pge-header">
            <Navigation />
          </Box>
        </>
      )}
      <Box id="global-notifications-banner">
        {!isPGEServiceAreaMap && <LanguagePreferenceAlert />}
        <Notifications />
        <AccountDisconnectedBanner />
      </Box>
      <Box flexGrow={1}>{element}</Box>
      <Backdrop />
      <Box flexShrink={0}>{!isPGEServiceAreaMap && <PgeFooter />}</Box>
      <Helmet>
        <meta name="google" content="notranslate" />
        <script>{`
        ;(function(d, s, c) {
            var j = d.createElement(s),
            t = d.getElementsByTagName(s)[0]
            j.src = '/static/scripts/elastic-apm-rum.umd.min.js'
            j.onload = function() {window.elasticApm && window.elasticApm.init(c)}
            t.parentNode.insertBefore(j, t)
          })(document, 'script', {
            serviceName: 'cs_client_${config.env}',
            environment:'${config.env}',
            serverUrl: '${config.apm.serverUrl}',
            active: ${config.apm.active},
          })
        `}</script>
        {!isPGEServiceAreaMap && (
          <script src="https://nebula-cdn.kampyle.com/wu/646316/onsite/embed.js" />
        )}
      </Helmet>
    </Box>
  );

  return (
    <ThemeProvider theme={theme}>
      <TransitionProvider>
        <NotificationsContextProvider>
          <AccountsContextProvider>
            <PromotionProvider context={{ ...pageContext, ...pageResources }}>
              <EVChargerFormStateProvider>
                <RebateOnlyFormStateProvider>
                  <PathProvider context={pageContext.slugPathMap}>
                    <CssBaseline />
                    <TranslationLoader />
                    <ErrorBoundary>
                      <SessionTimeout />
                      {/* <StormInfoMessage path={pageResources?.page?.path} /> */}
                      {isBrowser && (
                        <SearchProvider config={searchConfig}>
                          {renderPageComponents()}
                        </SearchProvider>
                      )}
                      {!isBrowser && renderPageComponents()}
                    </ErrorBoundary>
                  </PathProvider>
                </RebateOnlyFormStateProvider>
              </EVChargerFormStateProvider>
            </PromotionProvider>
          </AccountsContextProvider>
        </NotificationsContextProvider>
      </TransitionProvider>
    </ThemeProvider>
  );
};

export default wrapPageElement;
