import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { ColorName } from '../../../constants';

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    root: {
      width: '100%',
      display: 'flex',
      boxSizing: 'border-box',
      contain: 'content',
      position: 'relative',
      flexDirection: 'column',
    },
    content: {
      display: 'flex',
      width: '100%',
      flex: 1,
      flexDirection: 'column',
      alignItems: 'start',
    },
    text: {
      padding: 0,
      textAlign: 'inherit',
      color: ColorName.LightBlue,
      fontSize: theme.typography.pxToRem(20),

      '&:hover, &:focus': {
        background: 'none',
        textDecoration: 'underline',
      },
    },
  });
});

export default useStyles;
