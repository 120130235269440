import React, { useContext } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { FormControlLabel, FormGroup, Switch } from '@material-ui/core';
import {
  LanguageContext,
  SupportedLanguages,
} from '../../providers/LanguageProvider';
import { useTranslation } from '../../hooks/useTranslation';
import LanguagePreferencePopup from '../language-preference-popup/LanguagePreferencePopup';
import useAuth from '../../hooks/useAuth';
const useStyles = makeStyles((theme: Theme) => ({
  toggleButton: {
    '& .MuiSwitch-root': {
      width: '60px',
      height: '39px'
    },
    marginLeft: theme.spacing(2),
    '& .MuiFormControlLabel-root': {
      '& .MuiFormControlLabel-label': {
        fontSize: '0.875rem',
      },
    },
    '& .MuiSwitch-track': {
      borderRadius: '13px',
      backgroundColor: '#ffffff !important',
      height:'18px',
      opacity:'1.5'
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track':{
      marginLeft: '1px',
      opacity:'1.5 !important'
    },
    '& .MuiSwitch-thumb': {
      backgroundColor: '#3D5265',
      width: '15px',
      height: '15px',
      marginTop: '4px',
      marginLeft: '4px',
    },
    '& .Mui-checked': {
      '& .MuiSwitch-thumb':{
        marginLeft: '3px !important',
      },
    }
  },
}));
const LanguageToggle = ({}: any) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { handleLanguageChange, language } = useContext(LanguageContext);
  const { isAuthenticated } = useAuth();
  const handleToggleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.target.checked
      ? handleLanguageChange(SupportedLanguages.Spanish, true, true)
      : handleLanguageChange(SupportedLanguages.English, true, true);
  };
  return (
    <>
      <FormGroup className={classes.toggleButton}>
        <FormControlLabel
          control={
            <Switch
              checked={language === SupportedLanguages.Spanish}
              onChange={handleToggleChange}
            />
          }
          label={
            language === SupportedLanguages.English
              ? t('SPANISH_LABEL')
              : t('ENGLISH_LABEL')
          }
        />
      </FormGroup>
      {isAuthenticated ? <LanguagePreferencePopup /> : null}
    </>
  );
};
export default LanguageToggle;
