import React, { useRef } from 'react';
import Map from '../embed-map/Map';
import PgeVideoPlayer from '../pge-video-player/PgeVideoPlayer';
import RichText from '../rich-text';
import useStyles from './MediaWrapper.styles';
import { MediaWrapperProps as Props } from './types.d';
import { useComponentSize } from '../../util/style-utils';
import Button from '@material-ui/core/Button';
import { GetApp } from '@material-ui/icons';
import { useTranslation } from '../../hooks/useTranslation';

function MediaWrapper({
  embedMediaHeader,
  // embedMediaAltText,
  embedMediaCaption,
  url,
  googleMapUrl,
  esri,
  vimeoEmbedUrl,
  vimeoDownloadUrl,
}: Props): ElementNode {
  const classes = useStyles();
  const videoDivRef = useRef<HTMLDivElement>(null);
  const { width } = useComponentSize(videoDivRef);
  const { t } = useTranslation();

  return (
    <figure className={classes.root} ref={videoDivRef}>
      {embedMediaHeader && (
        <div className={classes.header}>{embedMediaHeader}</div>
      )}
      {googleMapUrl && <Map src={googleMapUrl} />}
      {esri && <Map src={esri} />}
      {url && (
        <div>
          <PgeVideoPlayer
            video={{
              url: url,
              width: `${width}px`,
              height: `${(width / 16) * 9}px`,
            }}
          />
        </div>
      )}
      {vimeoEmbedUrl && (
        <PgeVideoPlayer
          video={{
            url: vimeoEmbedUrl,
            width: `${width}px`,
            height: `${(width / 16) * 9}px`,
          }}
        />
      )}
      {vimeoEmbedUrl && vimeoDownloadUrl && (
        <Button
          component={'a'}
          color="primary"
          size="large"
          href={vimeoDownloadUrl}
          target="_blank"
          rel="noopener noreferrer"
          className={classes.button}
          endIcon={<GetApp />}
        >
          {t('DOWNLOAD')}
        </Button>
      )}
      <RichText wrap="figcaption">{embedMediaCaption}</RichText>
    </figure>
  );
}

export default MediaWrapper;
