import React, { FunctionComponent, useContext, useEffect } from 'react';
import Container from '@material-ui/core/Container';
import PgeButton, { IPGEButtonProps } from '../pge-button/PGEButtons';
import { Field } from '../../contentful.d';
// eslint-disable-next-line import/no-unassigned-import
import './styles/hero.css';

import TEST_IDS from '../../constants/test_ids';
import { useTranslation } from '../../hooks/useTranslation';
import { Card, Grid, Typography, makeStyles } from '@material-ui/core';
import SignIn from '../sign-in-form/SignIn';
import { useIsMobile } from '../../util/style-utils';
import useAuth from '../../hooks/useAuth';

interface LandingHeroImageProps {
  heading: string;
  image?: string;
  headingLineTwo?: string;
  subheading?: string;
  copy?: string;
  ctaText?: string;
  ctaLink?: string;
  button?: IPGEButtonProps | null;
  readonly imagePosition?: Field['Position'];
  showHeroSignInSection: boolean;
  signInShow?: boolean;
}

const useStyles = makeStyles(theme => ({
  gridContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
  },
  signIn: {
    margin: '0px 50px',
    textShadow: 'none',
  },
  card: {
    height: '100%',
    minWidth: 275,
    display: 'flex',
    flexDirection: 'column',
    padding: '1em 2em',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
}));
export const LandingHeroImage: FunctionComponent<LandingHeroImageProps> = ({
  image,
  heading,
  headingLineTwo,
  subheading,
  copy,
  ctaText,
  ctaLink,
  button,
  imagePosition,
  signInShow,
  showHeroSignInSection,
}) => {
  const backgroundPictureUrl = image ? `url(${image})` : 'none';
  const heroImageCSS =
    // HCL asked to keep this code to engage it post MVP.
    'linear-gradient(90deg, #071A2BE6, #122B417F, #1C304A00) ,' +
    backgroundPictureUrl;
  const { t } = useTranslation();
  const classes = useStyles();
  const isMobile = useIsMobile();

  const { isAuthenticated } = useAuth();
  return (
    <>
      <div
        className={`position-${imagePosition} hero-container`}
        data-testid={TEST_IDS.LANDING_HERO_HEADER}
        style={
          signInShow
            ? {
                padding: '50px 0 50px 0',
                backgroundImage: heroImageCSS,
                ...(!isAuthenticated && { height: '560px' }),
              }
            : {
                padding: '50px 0 100px 0',
                backgroundImage: heroImageCSS,
                ...(!isAuthenticated && { height: 'unset' }),
              }
        }
      >
        {signInShow && !isAuthenticated && !isMobile ? (
          <Container maxWidth="lg">
            <Grid
              container
              className={classes.gridContainer}
              spacing={4}
              alignItems="stretch"
              justify="center"
            >
              <Grid item xs={12} md={5} className={classes.signIn}>
                {showHeroSignInSection && (
                  <Card className={classes.card}>
                    <Typography variant={'h1'}>
                      {t('CUSTOMER_SIGN_IN')}
                    </Typography>
                    <SignIn isEmbedded={true} />
                  </Card>
                )}
              </Grid>
              <Grid item xs={12} md={7} className="hero-content">
                {heading && <h1>{heading}</h1>}
                {headingLineTwo && (
                  <h1 className="heading-two">{headingLineTwo}</h1>
                )}
                {subheading && <h3>{subheading}</h3>}
                {copy && <p>{copy}</p>}
                {button && <PgeButton {...button} />}
                {ctaText && <a href={ctaLink}>{ctaText}</a>}
              </Grid>
            </Grid>
          </Container>
        ) : (
          <Container className="hero-content">
            {heading && <h1>{heading}</h1>}
            {headingLineTwo && (
              <h1 className="heading-two">{headingLineTwo}</h1>
            )}
            {subheading && <h3>{subheading}</h3>}
            {copy && <p>{copy}</p>}
            {button && <PgeButton {...button} />}
            {ctaText && <a href={ctaLink}>{ctaText}</a>}
          </Container>
        )}
      </div>
    </>
  );
};
