import React from 'react';
import { Typography } from '@material-ui/core';
import ProgramCard from '../../program-card';
import RichText, { richTextStyles } from '../../rich-text';
import useAttrs from '../../../hooks/useAttrs';
import { CardLayoutProps as Props, Omits } from './types.d';
import useStyles from './CardLayout.styles';

function CardLayout({
  promoHeader,
  promoIntroCopy,
  addItems,
  columns,
  ...props
}: Props): ElementNode {
  // Default props
  const _columns = columns || '1';

  // Vars
  const richText = richTextStyles();
  const { className = '', ...attrs } = useAttrs<Props>(props, Omits);
  const classes = useStyles();

  return (
    <section
      className={`CardLayout ${classes.root} ${className}`}
      aria-label={promoHeader || undefined}
      {...attrs}
    >
      {promoHeader && <Typography variant="h1">{promoHeader}</Typography>}
      <RichText className={`RichText ${richText.styles}`}>
        {promoIntroCopy}
      </RichText>
      <div className={`${classes.container} col-${_columns}`}>
        {addItems?.map((item, index) => (
          <ProgramCard
            column={_columns}
            key={`${item?.contentful_id}${index}`}
            {...item}
          />
        ))}
      </div>
    </section>
  );
}

export default CardLayout;
