import React, { FC, ReactElement } from 'react';
import ContentList from '../content-list';
import RichText from '../rich-text';
import Markdown, { MarkdownToJSX } from 'markdown-to-jsx';
import { Typography, makeStyles, createStyles, Theme } from '@material-ui/core';
import PgeButton from '../pge-button/_PgeButton';
import { TableProps as Props, OverrideProps as OP } from './types';

const useStyles = makeStyles(({ spacing }: Theme) =>
  createStyles({
    root: {
      margin: spacing(5, 0, 0),
    },
  }),
);

const options: MarkdownToJSX.Options = {
  overrides: {
    h1: ({ children }: OP) => (
      <Typography component="h6" variant="h4">
        {children}
      </Typography>
    ),
    p: ({ children }: OP) => <Typography>{children}</Typography>,
    a: ({ children, href }: OP) => (
      <PgeButton link={href} theme="Text" display="Inline">
        {children}
      </PgeButton>
    ),
  },
  forceBlock: true,
};

const TableWrapper: FC<Props> = ({
  author,
  tableHeader,
  tableIntroCopy,
  columnBackground,
}) => {
  const [header, ...body] = author?.tableData || [];
  const classes = useStyles();

  const rows: ReactElement[][] = body.map(item => {
    if (!item) {
      return [];
    }

    return item.map(text => {
      const _text = text ?? '';

      return <Markdown options={options}>{_text}</Markdown>;
    });
  });

  const rich = (): ReactElement => <RichText>{tableIntroCopy}</RichText>;

  return (
    <figure className={`uiTableExtention ${classes.root}`}>
      <ContentList
        heading={tableHeader}
        introCopy={rich()}
        tableHeader={header}
        rows={rows}
        columnBackground={columnBackground}
      />
    </figure>
  );
};

export default TableWrapper;
