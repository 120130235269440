// Object to match ContentfulFields
export const ColorName = Object.freeze({
  Grey: '#F7F7F7',
  DarkGrey: '#686C71',
  DarkBlue: '#1C304A',
  LightBlue: '#27788C',
  Blue: '#EAF5f8',
  None: '#FFF',
  RiverBlue: '#E1F8FA',
});

export type Color = {
  Name: keyof typeof ColorName;
};
