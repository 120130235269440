import { ComponentPropsWithoutRef } from 'react';
import { CardLayoutFragment } from '../../../__generated__/gatsby-types';
import { Field } from '../../../contentful.d';
import { ProgramCardProps } from '../../program-card';

export const Omits = ['aria-label', 'style', 'colorBackground'] as const;

interface BaseProps
  extends CardLayoutFragment,
    ComponentPropsWithoutRef<'section'> {
  readonly columns?: Field['Column'];
  readonly addItems?: Array<Readonly<ProgramCardProps>>;
}

type CardLayoutProps = Partial<Omit<BaseProps, typeof Omits[number]>>;
