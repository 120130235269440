import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import colors from '../../themes/main-colors';
import Container from '@material-ui/core/Container';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    wordBreak: 'break-word',
    padding: 0,
  },
  header: {
    color: colors.noirBlur,
    fontWeight: 'bold',
    fontSize: theme.spacing(2.5),
    lineHeight: theme.typography.pxToRem(28),
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.spacing(2),
      lineHeight: theme.typography.pxToRem(20),
    },
  },
  desc: {
    color: colors.noirBlur,
    fontSize: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.spacing(1.75),
    },
    '& > div > *': {
      margin: 0,
    },
  },
}));

interface INotesProps {
  noteHeading?: string;
  noteCopy?: any;
}

const Note = (props: INotesProps) => {
  const classes = useStyles();

  return (
    <Container className={classes.root}>
      {props.noteHeading && (
        <div className={classes.header}>{props.noteHeading}</div>
      )}
      {props.noteCopy && <div className={classes.desc}>{props.noteCopy}</div>}
    </Container>
  );
};
export default Note;
