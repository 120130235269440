import React, { FC } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import PgeButton, { IPGEButtonProps } from '../pge-button/PGEButtons';
import { Typography } from '@material-ui/core';
import colors from '../../themes/main-colors';
import Container from '@material-ui/core/Container';
import RichText, { richTextStyles } from '../rich-text';
import { ModuleCallToActionFragment as Fragment } from '../../__generated__/gatsby-types';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      width: '100%',
      alignItems: 'center',
      padding: theme.spacing(5),
    },
    main: {
      boxShadow: '0px 3px 6px #00002930',
      borderRadius: theme.spacing(0.625),
    },
    default: {
      backgroundColor: colors.white,
    },
    primary: {
      backgroundColor: colors.lightBlue1,
    },
    secondary: {
      backgroundColor: colors.lightGray2,
    },
    gradient: {
      background: '#3D5265',
    },
    bgImage: (props: CtaProps) => ({
      backgroundImage: `URL(${props.image})`,
      backgroundSize: 'cover',
    }),
    imageContainer: (props: CtaProps) => ({
      background:
        'linear-gradient(90deg, #071A2BE6, #1632485B, #1C304A00),' +
        `URL(${props.image})`,
      backgroundSize: 'cover',
    }),
    headerStylePrimary: {
      letterSpacing: '0.720px',
      fontSize: theme.spacing(4.5),
      fontFamily: 'Forma-DJR-Display',
      color: colors.noirBlur,
      fontWeight: 'bold', // ???
      lineHeight: theme.typography.pxToRem(36),
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.spacing(3),
      },
    },
    descStylePrimary: {
      color: colors.noirBlur,
      marginTop: theme.spacing(2.5),

      '& p': {
        fontSize: '1.1rem',
      },
    },
    headerStyleSecondary: {
      fontSize: theme.spacing(4.5),
      fontFamily: 'Forma-DJR-Display',
      letterSpacing: '0.720px',
      color: theme.palette.common.white,
      fontWeight: 'bold', // ???
      lineHeight: theme.typography.pxToRem(36),
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.spacing(3),
      },
    },
    descStyleSecondary: {
      fontSize: theme.spacing(3),
      color: theme.palette.common.white,
      marginTop: theme.spacing(2.5),

      '& p': {
        fontSize: '1.1rem',
      },
      '& a, & button': {
        color: 'inherit',
        textDecoration: 'underline',
      },
    },
    buttonStyle: {
      marginTop: theme.spacing(2.5),
    },
  }),
);

interface CtaProps {
  header?: string;
  description?: Fragment['shortDescription'];
  button?: IPGEButtonProps | null;
  bgMode?: string;
  image?: string;
}

const getBgModeClass = (bgMode: string, props: CtaProps) => {
  let rootClass = '';
  const classes = useStyles(props);

  switch (bgMode) {
    case 'Blue':
      rootClass = `${classes.main} ${classes.primary}`;
      break;
    case 'Grey':
      rootClass = `${classes.main} ${classes.secondary}`;
      break;
    case 'Gradient':
      rootClass = `${classes.main} ${classes.gradient}`;
      break;
    case 'Image':
      rootClass = `${classes.main} ${classes.bgImage}`;
      break;
    default:
      rootClass = `${classes.main} ${classes.default}`;
      break;
  }

  let primaryHeader = classes.headerStyleSecondary;
  let descClass = `${classes.descStyleSecondary} bkg-Dark`;

  if (bgMode === 'Blue' || bgMode === 'Grey' || bgMode === 'None') {
    primaryHeader = classes.headerStylePrimary;
    descClass = classes.descStylePrimary;
  }

  return {
    rootClass: rootClass,
    primaryHeader: primaryHeader,
    descClass: descClass,
  };
};

const Cta: FC<CtaProps> = props => {
  const classes = useStyles(props);
  const richText = richTextStyles();
  const { bgMode = '', header, description, button } = {
    ...props,
  };
  const { rootClass, primaryHeader, descClass } = getBgModeClass(bgMode, props);

  return (
    <div className={`${classes.root} ${rootClass}`}>
      <Container>
        {header && <div className={primaryHeader}>{header}</div>}
        {description && (
          <RichText className={`${richText.styles} ${descClass}`}>
            {description}
          </RichText>
        )}
        {button && (
          <div className={classes.buttonStyle}>
            <PgeButton {...button} disableRippleEffect />
          </div>
        )}
      </Container>
    </div>
  );
};

export default Cta;
