import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { ColorName, Shadow } from '../../../constants';
import { StaticListProps as Props } from './types.d';

const useStyles = makeStyles((theme: Theme) => {
  const { palette } = theme;

  // Colors
  const { white } = palette.common;
  const text = palette.text.primary;
  const darkGrey = palette.grey[700];

  return createStyles({
    // Static List
    root_StaticList: {
      boxSizing: 'border-box',
      contain: 'content',
      marginTop: theme.spacing(2),
      overflow: 'hidden',
      borderRadius: '5px',
      color: text,
      boxShadow: Shadow.ONE,

      '&.bkg-None': {
        backgroundColor: ColorName['None'],
      },

      '&.bkg-Grey': {
        backgroundColor: ColorName['Grey'],
      },

      '&.bkg-Blue': {
        backgroundColor: ColorName['Blue'],
      },
    },

    hero: {
      display: 'flex',
      position: 'relative',
      alignItems: 'center',
      overflow: 'hidden',
      borderRadius: '5px 5px 0 0',
      height: '275px',

      '&::before': {
        display: 'block',
        width: '100%',
        paddingTop: '22%',
        content: '""',
      },

      '& img': {
        width: '100%',
        position: 'absolute',
        height: '275px',
        objectFit: 'cover',
        objectPosition: ({ imagePosition }: Props) => imagePosition,
      },
    },

    list: {
      padding: theme.spacing(2),
      counterReset: 'list-item 1',

      [theme.breakpoints.up('md')]: {
        padding: theme.spacing(6),
      },

      '& header': {
        counterIncrement: 'list-item -1',
      },
    },

    // List Item
    root_ListItem: {
      boxSizing: 'border-box',
      contain: 'content',
      display: 'flex',
      padding: theme.spacing(4, 2),
      marginTop: theme.spacing(3),
      overflow: 'hidden',
      backgroundColor: white,
      boxShadow: Shadow.ONE,

      '&::before': {
        flex: 'auto',
        maxWidth: '40px',
        counterIncrement: 'list-item',
        content: 'counter(list-item)',
        font: `700 ${theme.spacing(6)}px / 1 ${theme.typography.fontFamily}`,
        color: darkGrey,
        textAlign: 'center',
        overflow: 'hidden',
        [theme.breakpoints.up('md')]: {
          maxWidth: '80px',
          fontSize: theme.spacing(9),
        },
      },

      '&.withImage::before': {
        flex: '0 1px',
      },
    },

    icon: {
      display: 'flex',
      flex: 'auto',
      maxWidth: '40px',
      justifyContent: 'center',
      alignItems: 'start',
      overflow: 'hidden',

      [theme.breakpoints.up('md')]: {
        maxWidth: '80px',
      },

      '& img': {
        width: '100%',
      },
    },

    content: {
      flex: 1,
      padding: theme.spacing(0, 0.5, 0, 2),
      overflow: 'hidden',

      '& h2': {
        fontSize: '1.4rem',
        fontWeight: 700,

        [theme.breakpoints.up('md')]: {
          fontSize: '1.8rem',
        },
      },

      '& .RichText': {
        '& hr': {
          backgroundColor: darkGrey,
        },
      },
    },
  });
});

export default useStyles;
