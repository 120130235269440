import React, { FC, useState, useRef, useEffect } from 'react';
import { Card, CardContent, Button, Collapse } from '@material-ui/core';
import {
  RemoveCircleOutline,
  AddCircleOutline,
  Visibility,
  GetApp,
} from '@material-ui/icons';
import Overlay from '../../pge-overlay/_Overlay';
import RichText from '../../rich-text';
import PgeButton from '../../pge-button/_PgeButton';
import { WrapperImageProps as Props } from './types.d';
import useStyles from './WrapperImage.styles';

const WrapperImage: FC<Props> = ({
  image,
  imageHeader,
  imageIntroCopy,
  imageCaption,
  imageAltText,
  imageLongDescription,
  imageDisplayOptions,
  downloadFile,
  entryName,
  imageAlign,
  largerImage,
  mobileImage,
}) => {
  // Default Props
  const _align = imageAlign || 'Center';

  // Vars
  const mobileFile = mobileImage?.file;
  const imageHeight = mobileFile?.details?.image?.height ?? 1200;
  const imageWidth = mobileFile?.details?.image?.width ?? 360;
  const imageRatio = Math.floor((imageHeight / imageWidth) * 100);

  const [expanded, setExpanded] = useState(false);
  const [open, setOpen] = useState(false);
  const classes = useStyles({ imageRatio });
  const largeImage: string = largerImage?.file?.url || '';
  const downloadAsset: string = downloadFile?.file?.url || '';
  const downloadIcon: boolean = !!imageDisplayOptions?.includes('Download');
  const viewIcon: boolean = !!imageDisplayOptions?.includes('ViewLarger');
  const detailsIcon: boolean = !!imageDisplayOptions?.includes('Details');

  const $img = useRef<HTMLDivElement>(null);
  const $btn = useRef<HTMLButtonElement>(null);
  const [expandImage, setExpandImage] = useState(false);
  const toggleDetails = (): void => setExpanded(!expanded);

  useEffect(() => {
    if (expandImage) {
      $img.current?.classList.add('isExpanded');
      $btn.current?.setAttribute('disabled', '');
    }
  }, [expandImage]);

  const content = (): JSX.Element => {
    return (
      <picture className={classes.overlayImage}>
        {mobileFile && (
          <>
            <source media="(max-width:767px)" srcSet={mobileFile.url} />
            <source media="(min-width:768px)" srcSet={largeImage} />
          </>
        )}
        <img src={largeImage} alt="" />
      </picture>
    );
  };

  return (
    <article className={`WrapperImage ${classes.root}`}>
      <Overlay
        entryName={entryName}
        html={content()}
        open={open}
        setOpen={setOpen}
      />
      <header className={`${classes.header} align-${_align}`}>
        {imageHeader && <h3 className={classes.heading}>{imageHeader}</h3>}
        {<RichText className={classes.introCopy}>{imageIntroCopy}</RichText>}
        {image?.file?.url && (
          <div
            className={mobileFile ? `${classes.image} ${classes.expand}` : ''}
            ref={$img}
          >
            <picture className={!mobileFile ? `${classes.imageContainer}` : ''}>
              {mobileFile && (
                <>
                  <source media="(max-width:767px)" srcSet={mobileFile.url} />
                  <source media="(min-width:768px)" srcSet={image.file.url} />
                </>
              )}
              <img
                src={image.file.url}
                alt={imageAltText?.imageAltText}
                width="700"
                height="500"
              />
            </picture>
            {mobileFile && (
              <PgeButton
                className={classes.showMore}
                theme="Pill"
                startIcon="Plus"
                onClick={() => setExpandImage(true)}
                ref={$btn}
              >
                Show more
              </PgeButton>
            )}
          </div>
        )}
      </header>
      {imageCaption && (
        <RichText className={classes.caption}>{imageCaption}</RichText>
      )}
      {imageDisplayOptions && (
        <menu className={classes.buttonContainer}>
          {downloadIcon && downloadAsset && (
            <Button
              component={'a'}
              color="primary"
              size="large"
              href={downloadAsset}
              target="_blank"
              rel="noopener noreferrer"
              className={classes.button}
              endIcon={<GetApp />}
            >
              Download
            </Button>
          )}
          {viewIcon && largeImage && (
            <Button
              size="large"
              color="primary"
              className={classes.button}
              endIcon={<Visibility />}
              onClick={() => setOpen(true)}
            >
              View
            </Button>
          )}
          {detailsIcon && (
            <Button
              size="large"
              color="primary"
              className={`${classes.button} ${expanded ? 'expanded' : ''}`}
              onClick={toggleDetails}
              aria-expanded={expanded}
              endIcon={
                expanded ? <RemoveCircleOutline /> : <AddCircleOutline />
              }
            >
              Details
            </Button>
          )}
        </menu>
      )}
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent className={classes.expandContainer}>
          <Card className={classes.longDescriptionContainer}>
            <RichText className={classes.longDescription}>
              {imageLongDescription}
            </RichText>
          </Card>
        </CardContent>
      </Collapse>
    </article>
  );
};

export default WrapperImage;
