import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { ColorName } from '../../../constants';

const useStyles = makeStyles(({ spacing, typography, breakpoints }: Theme) => {
  return createStyles({
    card: {
      contain: 'content',
      padding: spacing(4, 0),
      overflow: 'hidden',
      '&.bkg-Gray': {
        backgroundColor: ColorName['Grey'],
      },

      '&.bkg-Blue': {
        backgroundColor: ColorName['Blue'],
      },

      '&.bkg-None': {
        backgroundColor: 'transparent',
      },

      '& h1, & > p': {
        boxSizing: 'content-box',
        maxWidth: 800,
        marginLeft: 'auto',
        marginRight: 'auto',
        padding: spacing(0, 2),

        [breakpoints.up('sm')]: {
          padding: spacing(0, 1.25),
        },
      },

      '& h1': {
        marginTop: spacing(2.5),
        marginBottom: spacing(2),
        [breakpoints.up('sm')]: {
          marginTop: spacing(4),
          marginBottom: spacing(2),
        },
      },

      '& > p': {
        marginTop: spacing(3),

        [breakpoints.up('sm')]: {
          marginTop: spacing(4.5),
        },
      },

      '& h1 + p': {
        marginTop: spacing(2),
      },

      '& .graphical span': {
        width: 1,
        height: 1,
        position: 'absolute',
        overflow: 'hidden',
      },
    },
    scroll: {
      padding: spacing(1),
      height: typography.pxToRem(500),
      overflowY: 'auto',
    },
  });
});

export default useStyles;
