/** @jsx jsx */
import { jsx, css } from '@emotion/core';

import React, { Fragment } from 'react';
import {
  createStyles,
  makeStyles,
  Theme,
  useTheme,
} from '@material-ui/core/styles';
import { navigate } from 'gatsby';
import Container from '@material-ui/core/Container';
import IconButton from '@material-ui/core/IconButton';

import { useTranslation } from '../../hooks/useTranslation';
import { usePromotionContext } from '../../providers/PromotionProvider';
import PGEButton from '../buttons/PGE-Button';
import PGELogo from '../logos/PGELogo';
import ROUTES from '../../routes';
import { NavigationUser } from './NavigationUser';
import { NavigationMegaMenu } from './NavigationMegaMenu';
import MobileHeader from '../mobile-header';
import { isMobile } from '../../util/style-utils';
import colors from '../../themes/main-colors';
import useAuth from '../../hooks/useAuth';
import { SearchBox, WithSearch } from '@elastic/react-search-ui';
import SearchIcon from '@material-ui/icons/Search';
// eslint-disable-next-line import/no-unassigned-import
import './navigation.css';
import { ColorName } from '../../constants';

import TEST_IDS from '../../constants/test_ids';
import LanguageToggle from '../language-toggle';
import { Button, Box } from '@material-ui/core';
import FullSurveyHeader from '../my-energy-use/full-survey-header/FullSurveyHeader';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    registerButton: {
      marginLeft: theme.spacing(1),
    },
    search: {
      padding: theme.spacing(0, 2.5),
      display: 'inline-flex',
      verticalAlign: 'bottom',
    },
    searchInputField: {
      borderRadius: 0,
    },
    elasticSearchBox: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      '& .sui-search-box__wrapper': {
        width: 300,
      },
      '& .sui-search-box__submit': {
        display: 'none',
      },
      '& .sui-search-box__text-input': {
        padding: theme.spacing(1),
      },
      '& .MuiSvgIcon-root': {
        margin: theme.spacing(1.5, 0, 0, -5),
        zIndex: 1,
        fill: colors.noirBlur,
        height: theme.spacing(2),
      },
      '& .MuiButton-outlinedPrimary': {
        marginLeft: theme.spacing(2),
      },
      '& .sui-search-box__autocomplete-container li': {
        textAlign: 'left',
      },
      '& .sui-search-box__section-title': {
        textAlign: 'left',
      },
      '& .sui-search-box__autocomplete-container': {
        top: '99%',
      },
    },
    promotionPage: {
      background: `${ColorName.Grey}`,

      '& .MuiFormControlLabel-root': {
        '& .MuiFormControlLabel-label': {
          fontSize: '0.875rem',
        },
      },
    },
    spaceholder: {
      display: 'flex',
      width: '50%',
    },
    buttons: {
      color: colors.buttonColor,
      backgroundColor: colors.navBarBackground,
      borderColor: colors.buttonColor,
      borderRadius: theme.spacing(3),
      minWidth: '100px',
      borderWidth: 'revert',
      height: theme.spacing(3.5),
      fontWeight: 'bold',
      '&:hover': {
        color: colors.buttonColor,
        backgroundColor: colors.navBarBackground,
        borderColor: colors.buttonColor,
      },
      '&:active': {
        color: colors.buttonColor,
        backgroundColor: colors.navBarBackground,
        borderColor: colors.buttonColor,
      },
    },
  }),
);

export const Navigation = () => {
  const { signOut, isAuthenticated } = useAuth();
  const { promotion, noLogo, noSignIn } = usePromotionContext();
  const { t } = useTranslation();
  const classes = useStyles();
  const theme = useTheme();
  const isBrowser = !(typeof window === 'undefined');
  const isOutages =
    isBrowser && window.location.pathname.replace(/\/$/, '') === ROUTES.OUTAGES;
  const isFullSurveyPage =
    isBrowser &&
    window.location.pathname.replace(/\/$/, '') === ROUTES.HOME_ENERGY_ANALYSIS;
  const isMobileView = isMobile();
  if ((isMobileView && !isFullSurveyPage) || isOutages) {
    // TODO: For MVP, we're not showing a title.
    return (
      <MobileHeader title={isOutages && isMobileView ? t('OUTAGE_MAP') : ''} />
    );
  }
  if (isFullSurveyPage) {
    // TODO: For MVP, we're not showing a title.
    return (
      <FullSurveyHeader
        title={t('HOME_ENERGY_ANALYSIS')}
        isMobileView={isMobileView}
      />
    );
  }

  return (
    <div data-swiftype-index="false">
      {!promotion && (
        <div className="app-bar" data-testid={TEST_IDS.APP_BAR}>
          <Container
            css={{
              [`@media (min-width: ${theme.breakpoints.values.lg}px)`]: {
                maxWidth: '100%',
                padding: '0 4.23% 0.875rem 4.23% !important',
              },
            }}
          >
            <div className="logo-wrapper">
              {isBrowser && (
                <IconButton
                  edge="start"
                  aria-label={t('NAVIGATE_HOME')}
                  onClick={() => navigate('/')}
                >
                  <PGELogo
                    className="logo"
                    color="#fff"
                    viewBox="0 0 108 108"
                  />
                </IconButton>
              )}
            </div>

            <div className={`user-wrapper ${classes.elasticSearchBox}`}>
              {isBrowser && (
                <WithSearch
                  mapContextToProps={({
                    searchTerm,
                    setSearchTerm,
                    results,
                  }: any) => ({
                    searchTerm,
                    setSearchTerm,
                    results,
                  })}
                >
                  {({ searchTerm, setSearchTerm, results }: any) => {
                    return (
                      <Fragment>
                        <SearchBox
                          autocompleteMinimumCharacters={3}
                          autocompleteResults={{
                            sectionTitle: 'Results',
                            titleField: 'title',
                            urlField: 'url',
                            shouldTrackClickThrough: true,
                            clickThroughTags: ['test'],
                          }}
                          autocompleteSuggestions={true}
                          debounceLength={0}
                          onSubmit={async (value: any) => {
                            await navigate('/search?q=' + value);
                            setSearchTerm(value);
                          }}
                          inputProps={{ className: classes.searchInputField }}
                        />
                        <SearchIcon
                          style={{
                            cursor: 'pointer',
                            height: 'unset',
                            position: 'relative',
                            top: '-5px',
                          }}
                          onClick={async (e: any) => {
                            await navigate('/search?q=' + searchTerm);
                            setSearchTerm(searchTerm);
                          }}
                        />
                      </Fragment>
                    );
                  }}
                </WithSearch>
              )}

              {!isAuthenticated && isBrowser && (
                <Fragment>
                  <LanguageToggle />
                </Fragment>
              )}
              {isAuthenticated && isBrowser && (
                <NavigationUser signOut={signOut} />
              )}
            </div>
          </Container>
        </div>
      )}

      {promotion && (
        <div className={`app-bar ${classes.promotionPage}`}>
          <Container>
            {!noLogo && (
              <div className="logo-wrapper">
                <IconButton
                  edge="start"
                  aria-label={t('NAVIGATE_HOME')}
                  onClick={() => navigate('/')}
                >
                  <PGELogo
                    className="logo"
                    color="#3D5265"
                    viewBox="0 0 108 108"
                  />
                </IconButton>
              </div>
            )}
            <div className="user-wrapper">
              {!isAuthenticated && !noSignIn && (
                <Fragment>
                  <LanguageToggle />
                  <PGEButton
                    data-testid={'sign-in-route-button'}
                    variant={'outlined'}
                    color={'secondary'}
                    onClick={async (e: any) => {
                      e.preventDefault();
                      await navigate(ROUTES.SIGN_IN);
                    }}
                    href={ROUTES.SIGN_IN}
                  >
                    {t('SIGN_IN')}
                  </PGEButton>
                </Fragment>
              )}
              {isAuthenticated && !noSignIn && (
                <NavigationUser signOut={signOut} />
              )}
            </div>
          </Container>
        </div>
      )}
      {!promotion && isBrowser && (
        <NavigationMegaMenu isAuthenticated={isAuthenticated} />
      )}
    </div>
  );
};
