import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import isEmpty from 'lodash/isEmpty';
import React, { FunctionComponent, useContext, useState } from 'react';
import { BrowserNotice } from '../../components/browser-check';
import { PlannedDownTimeWarning } from '../../components/planned-downtime/PlannedDownTimeWarning';
import useAccountForm from '../../hooks/useAccountForm';
import { useTranslation } from '../../hooks/useTranslation';
import { featureFlagsContext } from '../../providers/FeatureFlagsProvider';
import ROUTES from '../../routes';
import colors from '../../themes/main-colors';
import { useIsMobile } from '../../util/style-utils';
import PGEButton from '../buttons';
import EmailField from '../email-text-field';
import PasswordField from '../password-text-field';
import Link from '../text-link';
import Tooltip from '../tooltip';
import MFAVerification from './MFAVerification';
import SignInToggle from '../sign-toggle';
import QuickLoginForm from '../quick-login-form/QuickLoginForm';
import useQuickLogin from '../../hooks/useQuickLogin';
import QuickLoginStatusMessage from '../quick-login-form/QuickLoginStatusMessage';
import { Divider } from '@material-ui/core';
const useStyles = makeStyles(theme => ({
  submit: {
    backgroundColor: colors.orange,
    borderColor: colors.orange,
    '&:hover': {
      backgroundColor: colors.orange,
      borderColor: colors.orange,
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      alignItems: 'center',
    },
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      margin: '1em 0',
    },
  },
  signInBottom: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    margin: '.5em 0',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
  securityLink: {
    display: 'flex',
  },
  links: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    [theme.breakpoints.down('sm')]: {
      alignItems: 'center',
      marginTop: '-1em',
    },
  },
  plannedDownTimeWarningWrapper: {
    marginTop: '1rem',
  },
  checkbox: {
    '& .MuiFormControlLabel-label': {
      fontSize: '16px',
    },
  },
}));
export interface Props {
  redirectUrl?: string;
  newEmail?: string;
  previousEmail?: string;
  isEmbedded?: boolean;
  closeModal?: () => void;
}
const SignIn: FunctionComponent<Props> = (props: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const isMobile = useIsMobile();
  const [section, setSelection] = useState<string>('SignIn');
  const [showQuickLogin, setShowQuickLogin] = useState<boolean>(false);
  const { isPlannedDownTime } = useContext(featureFlagsContext);
  const { redirectUrl, newEmail, previousEmail, closeModal } = props;
  const {
    isTwoStepRequired,
    form,
    twoStepVerificationForm,
    handleSubmit,
    handleTwoFactorSubmit,
    handleResendOneTimeCode,
    handleForgotPassword,
  } = useAccountForm({
    redirectUrl,
    newEmail,
    previousEmail,
    closeModal,
  });

  const {
    quickform,
    quickLoginhandleSubmit,
    successMessage,
    handleBack,
  } = useQuickLogin({
    newEmail,
    previousEmail,
    closeModal,
  });
  if (typeof window !== 'undefined' && !window.navigator.cookieEnabled) {
    return <BrowserNotice />;
  }
  const onSendOneTimeLoginLink = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    quickLoginhandleSubmit();
    setShowQuickLogin(true);
  };
  return (
    <>
      {isPlannedDownTime ? (
        <div className={classes.plannedDownTimeWarningWrapper}>
          <PlannedDownTimeWarning />
        </div>
      ) : (
        <Grid container spacing={1} className={classes.container}>
          {!isTwoStepRequired && (
            <Grid item>
              <SignInToggle section={section} setSection={setSelection} />
            </Grid>
          )}
          <Grid item>
            {!isTwoStepRequired && section === 'SignIn' && (
              <>
                <form id={'login-form'} noValidate onSubmit={handleSubmit}>
                  <Grid item>
                    <EmailField
                      autoFocus
                      autoComplete="username"
                      inputProps={{ maxLength: 50 }}
                      {...form.props('email')}
                    />
                  </Grid>
                  <Grid item>
                    <PasswordField
                      defaultShow={isMobile}
                      autoComplete="current-password"
                      {...form.props('password')}
                    />
                  </Grid>
                  <Grid item className={classes.links}>
                    {!isTwoStepRequired && (
                      <>
                        <Grid item style={{ width: '100%' }}>
                          <Grid container className={classes.signInBottom}>
                            <Grid item>
                              <Link
                                plain
                                to={ROUTES.FORGOT_PASSWORD}
                                variant={'body2'}
                                onClick={e => {
                                  e.preventDefault();
                                  isMobile && closeModal && closeModal();
                                  handleForgotPassword();
                                }}
                              >
                                {t('FORGOT_PASSWORD')}
                              </Link>
                            </Grid>
                            {isMobile && (
                              <Grid item>
                                <Link
                                  plain
                                  to={ROUTES.GUEST_PAY}
                                  variant={'body2'}
                                  onClick={() => {
                                    isMobile && closeModal && closeModal();
                                  }}
                                >
                                  {t('PAY_AS_GUEST')}
                                </Link>
                              </Grid>
                            )}
                            <Grid item>
                              <Tooltip
                                title={t('SIGN_IN_SECURITY_INFO_TOOL_TIP')}
                              >
                                <div>
                                  <Link
                                    tooltip
                                    to="#"
                                    external
                                    variant={'body2'}
                                    onClick={e => {
                                      e.preventDefault();
                                      window.open(
                                        ROUTES.PRIVACY_POLICY,
                                        '_blank',
                                      );
                                    }}
                                  >
                                    {t('SIGN_IN_SECURITY_INFO')}
                                  </Link>
                                </div>
                              </Tooltip>
                            </Grid>
                          </Grid>
                        </Grid>
                      </>
                    )}
                  </Grid>
                  {!isMobile && (
                    <Grid item>
                      <Link plain to={ROUTES.GUEST_PAY} variant={'body2'}>
                        {t('PAY_AS_GUEST')}
                      </Link>
                    </Grid>
                  )}
                  <Grid item>
                    <Grid container className={classes.signInBottom}>
                      <Grid item className={classes.checkbox}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              onChange={form.onChange}
                              checked={form.values.rememberMe}
                              name={'rememberMe'}
                              color={'primary'}
                            />
                          }
                          label={t('REMEMBER_ME')}
                        />
                      </Grid>
                      <Grid item className={classes.buttonContainer}>
                        <PGEButton
                          id="sign-in-submit-btn"
                          type={'submit'}
                          variant={'contained'}
                          color={'primary'}
                          className={classes.submit}
                          disabled={!isEmpty(form.errors)}
                        >
                          {t('SIGN_IN')}
                        </PGEButton>
                      </Grid>
                    </Grid>
                  </Grid>
                </form>
              </>
            )}
            {!isTwoStepRequired && section === 'OneTimePay' && (
              <QuickLoginForm
                handleBack={handleBack}
                form={quickform}
                handleSubmit={quickLoginhandleSubmit}
                successMessage={successMessage}
              />
            )}
            {isTwoStepRequired && (
              <form
                id={'mfa-verification-form'}
                noValidate
                onSubmit={handleTwoFactorSubmit}
              >
                <MFAVerification
                  form={twoStepVerificationForm}
                  handleResendOneTimeCode={handleResendOneTimeCode}
                  isCompactMode={props.isEmbedded}
                />
              </form>
            )}
            <Grid item>
              <Grid
                container
                className={classes.signInBottom}
                style={{ flexDirection: 'column' }}
              >
                {props.isEmbedded && !isTwoStepRequired && (
                  <>
                    <Grid item style={{ width: '100%', marginTop: '.5em' }}>
                      <Divider />
                    </Grid>
                    <Grid item style={{ width: '100%', marginTop: '.5em' }}>
                      <span style={{ fontSize: '16px' }}>
                        {t('DO_YOU_HAVE_ACCOUNT_TEXT')}
                      </span>
                      <Link
                        plain
                        to={ROUTES.REGISTRATION}
                        onClick={() => {
                          isMobile && closeModal && closeModal();
                        }}
                        variant={'body2'}
                        style={{ textDecoration: 'none' }}
                      >
                        {t('REGISTER')}
                      </Link>
                    </Grid>
                  </>
                )}
                {isTwoStepRequired && (
                  <Grid item className={classes.securityLink}>
                    <Grid item style={{ flexGrow: 1 }} />
                    <Grid item style={{ marginTop: '.5em' }}>
                      <Tooltip title={t('SIGN_IN_SECURITY_INFO_TOOL_TIP')}>
                        <div>
                          <Link
                            tooltip
                            to="#"
                            external
                            variant={'body2'}
                            onClick={e => {
                              e.preventDefault();
                              window.open(ROUTES.PRIVACY_POLICY, '_blank');
                            }}
                          >
                            {t('SIGN_IN_SECURITY_INFO')}
                          </Link>
                        </div>
                      </Tooltip>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
      {/* Invisible re-captcha placeholder */}
      <Box id="sign-in"></Box>
    </>
  );
};
export default SignIn;
