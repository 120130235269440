import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import colors from '../../themes/main-colors';

const useStyles = makeStyles(theme => ({
  root: (props: columnProps) => ({
    flexGrow: 1,
    width: '100%',
    padding: theme.spacing(2, 0),
    borderRadius: theme.spacing(0.625),
    backgroundColor: props.backGroundColor
      ? props.backGroundColor
      : colors.white,
    '& .MuiGrid-item > h2': {
      marginTop: '0px !important',
    },
    '& .MuiGrid-spacing-xs-3': {
      margin: theme.spacing(0),
      width: '100%',
    },
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1),
    },
  }),
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  header: {    
    letterSpacing:'0.720px',
    fontFamily: 'Forma-DJR-Display',
    fontSize: theme.spacing(4.5),
    fontWeight: 'bold',
    color: colors.noirBlur,
    paddingLeft: theme.spacing(2.5),
    lineHeight: theme.typography.pxToRem(40),
    marginBottom: theme.spacing(2.5),
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.spacing(3),
      lineHeight: theme.typography.pxToRem(28),
      paddingLeft: theme.spacing(0),
      marginBottom: theme.spacing(1.25),
    },
  },
  description: {
    fontSize: theme.spacing(2.5),
    color: colors.noirBlur,
    marginTop: theme.spacing(2.5),
    marginBottom: theme.spacing(2.5),
    paddingLeft: theme.spacing(2.5),
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.spacing(2),
      paddingLeft: theme.spacing(0),
    },
  },
  grid: {
    '& .pge-contenful-H2': {
      marginTop: 0,
    },
    '& .MuiGrid-spacing-xs-3': {
      marginBottom: theme.spacing(6.25),
    },
    '& .MuiGrid-item': {
      padding: theme.spacing(0, 2.5),
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(0),
      },
      '& .spacingComponent.ContentfulContentBody': {
        marginTop: theme.spacing(2),
      },
      '& .WrapperImage': {
        [theme.breakpoints.down('sm')]: {
          marginTop: theme.spacing(2),
        },
        [theme.breakpoints.up('sm')]: {
          marginTop: theme.spacing(0),
        },
      },

      '& p.pge-contentful-body': {
        [theme.breakpoints.down('sm')]: {
          marginTop: theme.spacing(1),
        },
      },
      '& .SocialFeed > div': {
        [theme.breakpoints.down('sm')]: {
          padding: theme.spacing(2, 0),
        },
      },
    },
  },
  gridStyle: {
    borderLeft: '1px solid',
    borderLeftColor: colors.lightGray1,
    [theme.breakpoints.down('sm')]: {
      borderLeft: 'none',
    },
  },
}));

interface columnProps {
  data: any;
  backGroundColor?: any;
  shortDesc?: any;
  header?: any;
  leftNav?: any;
}

const Column = (props: columnProps) => {
  const classes = useStyles(props);
  let mdVal: any;
  if (props.leftNav) {
    mdVal = props.data.length === 1 ? 12 : 6;
  } else {
    mdVal =
      props.data.length === 1
        ? 12
        : props.data.length === 2
        ? 6
        : props.data.length === 3
        ? 4
        : 3;
  }

  const generateColumn = (comp: any, index: number) => {
    if (index === 0) {
      return (
        <Grid item xs={12} md={mdVal} key={index} className={`col${index + 1}`}>
          {comp}
        </Grid>
      );
    } else if (props.leftNav && index % 2 === 0) {
      return (
        <Grid item xs={12} md={mdVal} key={index} className={`col${index + 1}`}>
          {comp}
        </Grid>
      );
    } else {
      return (
        <Grid
          item
          xs={12}
          md={mdVal}
          key={index}
          className={`col${index + 1} ${classes.gridStyle}`}
        >
          {comp}
        </Grid>
      );
    }
  };
  return (
    <div className={`Column ${classes.root}`}>
      {(() => {
        return (
          <>
            {props.header && (
              <div className={classes.header}>{props.header}</div>
            )}
            {props.shortDesc && (
              <div className={classes.description}>{props.shortDesc}</div>
            )}
            <Grid
              container
              spacing={3}
              justify="flex-start"
              className={classes.grid}
            >
              {props.data.map((comp: any, index: number) => {
                return generateColumn(comp, index);
              })}
            </Grid>
          </>
        );
      })()}
    </div>
  );
};
export default Column;
