import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      contain: 'layout',
      width: '100%',
      marginTop: theme.spacing(7),

      '.CardLayout + & ': {
        marginTop: theme.spacing(14),
      },

      '& > .RichText': {
        marginBottom: theme.spacing(3),
      },
    },
    container: {
      display: 'flex',
      width: '100%',
      flexWrap: 'wrap',

      [theme.breakpoints.up('sm')]: {
        '& > *:not(:first-child)': {
          marginLeft: 'auto',
        },

        '& > *:not(:last-child)': {
          marginRight: 'auto',
        },

        '&.col-2 > *:nth-child(2n+3), &.col-3 > *:nth-child(3n+4)': {
          marginLeft: 0,
        },

        '&.col-2 > *:nth-child(2n+2), &.col-3 > *:nth-child(3n+3)': {
          marginRight: 0,
        },
      },
    },
    outline: {
      width: 1,
      height: 1,
      position: 'absolute',
      overflow: 'hidden',
    },
  }),
);

export default useStyles;
