import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { ColorName, Shadow } from '../../../constants';

const useStyles = makeStyles((theme: Theme) => {
  const { palette } = theme;

  // Colors
  const { white } = palette.common;
  const text = palette.text.primary;
  const popular = palette.success.main;
  const nueva = palette.warning.main;
  const lightGrey = palette.grey.A100;
  const darkGrey = palette.grey[700];

  return createStyles({
    // Program Card
    root: {
      width: '100%',
      display: 'flex',
      boxSizing: 'border-box',
      contain: 'content',
      padding: theme.spacing(6, 0, 4),
      marginTop: theme.spacing(3),
      position: 'relative',
      flexDirection: 'column',
      borderRadius: '5px',
      boxShadow: Shadow.ONE,

      '&.bkg-Grey': {
        backgroundColor: ColorName['Grey'],
      },

      '&.bkg-None': {
        backgroundColor: ColorName['None'],
      },

      '&.[class*=col-]': {
        [theme.breakpoints.up('sm')]: {
          padding: theme.spacing(6, 0),
        },
      },

      '&.col-1': {
        [theme.breakpoints.up('sm')]: {
          paddingRight: theme.spacing(4),
          flexDirection: 'row',
        },
      },

      '&.col-2': {
        [theme.breakpoints.up('sm')]: {
          width: 'calc(50% - 12px)',
        },
      },

      '&.col-3': {
        [theme.breakpoints.up('sm')]: {
          width: 'calc(33.33% - 16px)',
        },
      },
    },

    // Card Content
    content: {
      display: 'flex',
      width: '100%',
      flex: 1,
      padding: theme.spacing(0, 3),
      flexDirection: 'column',
      alignItems: 'start',

      '.col-1 &': {
        [theme.breakpoints.up('sm')]: {
          flex: 1,
        },
      },

      '& h2': {
        marginTop: '1rem',
        fontWeight: 700,
        color: text,
      },

      '& .RichText': {
        marginTop: '1rem',
        color: text,

        '.col-2 &, .col-3 &': {
          [theme.breakpoints.up('sm')]: {
            marginBottom: '2rem',
          },
        },
      },
    },

    // Badge
    badge: {
      width: theme.spacing(17),
      height: theme.spacing(4),
      position: 'absolute',
      top: 0,
      left: 0,
      textAlign: 'center',
      lineHeight: `${theme.spacing(4)}px`,
      textTransform: 'uppercase',
      borderRadius: '5px 0',

      '&.type-MostPopular': {
        color: white,
        background: popular,
      },

      '&.type-New': {
        color: white,
        background: nueva,
      },

      '&.type-SoldOut, &.type-Full': {
        color: darkGrey,
        background: lightGrey,
      },
    },

    image: {
      width: theme.spacing(17),
      height: theme.spacing(17),
      overflow: 'hidden',

      '& img': {
        display: 'block',
        width: '75%',
        height: '75%',
        margin: 'auto',
      },
    },

    // Main CTA
    cta: {
      marginTop: '2rem',
      alignSelf: 'stretch',

      '.col-2 &, .col-3 &': {
        [theme.breakpoints.up('sm')]: {
          marginTop: 'auto',
          alignSelf: 'start',
        },
      },

      '.col-1 &': {
        [theme.breakpoints.up('sm')]: {
          marginTop: 0,
          alignSelf: 'center',

          '&.with-Content': {
            marginTop: '2.25rem',
            alignSelf: 'start',
          },
        },
      },
    },

    // Supporting CTA
    support: {
      marginTop: '1rem',
      alignSelf: 'stretch',

      [theme.breakpoints.up('sm')]: {
        alignSelf: 'start',
      },
    },
  });
});

export default useStyles;
