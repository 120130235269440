import React, { useEffect, useState, useRef } from 'react';
import { Typography } from '@material-ui/core';
import PgeButton from '../../pge-button/_PgeButton';
import RichText, { richTextStyles } from '../../rich-text';
import useAttrs from '../../../hooks/useAttrs';
import { Omits, ProgramCardProps as Props } from './types.d';
import useStyles from './ProgramCard.styles';

function ProgramCard({
  heading,
  headingLink,
  leadStatement,
  shortDescription,
  image,
  ctaButton,
  ctaButtonLink,
  supportingCta,
  supportingCtaLink,
  programBadge,
  colorBackground,
  column,
  ...props
}: Props): ElementNode {
  // Default props
  const _badge = programBadge || 'None';
  const _color = colorBackground || 'None';
  const _column = column || '1';
  const _type = ctaButton?.buttonType || 'Primary';

  // Vars
  const hasBadge = _badge !== 'None';
  const badgeType = _badge.replace(/\s/g, '');
  const withContent = shortDescription ? 'with-Content' : '';
  const richText = richTextStyles();
  const [row, setRow] = useState(false);
  const listen = useRef(true);
  const { className = '', ...attrs } = useAttrs<Props>(props, Omits);
  const classes = useStyles();

  useEffect(() => {
    if (_column === '1') {
      const size = () => {
        if (listen.current && window.innerWidth > 768) {
          setRow(true);
          listen.current = false;
        }
        if (!listen.current && window.innerWidth < 767) {
          setRow(false);
          listen.current = true;
        }
      };

      window.addEventListener('resize', size, { passive: true });
      size();

      return () => {
        window.removeEventListener('resize', size);
      };
    }
  });

  return (
    <article
      className={`ProgramCard bkg-${_color} col-${_column} ${classes.root} ${className}`}
      aria-label={heading || undefined}
      {...attrs}
    >
      {hasBadge && (
        <Typography
          className={`${classes.badge} type-${badgeType}`}
          component="p"
          variant="subtitle2"
        >
          <small>{_badge}</small>
        </Typography>
      )}
      {image?.file?.url && (
        <picture className={classes.image}>
          <img src={image.file.url} alt="" width="100" height="100" />
        </picture>
      )}
      <div className={classes.content}>
        {heading && (
          <Typography variant="h1">
            {headingLink && (
              <PgeButton link={headingLink} display="Inline" theme="Text">
                {heading}
              </PgeButton>
            )}
          </Typography>
        )}
        {leadStatement && <Typography variant="h2">{leadStatement}</Typography>}
        <RichText className={`RichText ${richText.styles}`}>
          {shortDescription}
        </RichText>
        {!row && ctaButtonLink && (
          <PgeButton
            className={`${classes.cta} ${withContent}`}
            link={ctaButtonLink}
            theme={_type}
          >
            {ctaButton?.buttonText}
          </PgeButton>
        )}
        {supportingCtaLink && (
          <PgeButton
            className={classes.support}
            link={supportingCtaLink}
            theme="Text"
          >
            {supportingCta?.buttonText}
          </PgeButton>
        )}
      </div>
      {row && ctaButtonLink && (
        <PgeButton
          className={`${classes.cta} ${withContent}`}
          link={ctaButtonLink}
          theme={_type}
        >
          {ctaButton?.buttonText}
        </PgeButton>
      )}
    </article>
  );
}

export default ProgramCard;
