import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Size, Shadow } from '../../constants';

const useStyles = makeStyles((theme: Theme) => {
  const { palette } = theme;

  // Colors
  const { white } = palette.common;
  const { main: primary, contrastText: text } = palette.primary;
  const emphasis = palette.warning.main;
  const back = palette.grey[800];
  const disabled = palette.grey[600];
  const darkText = palette.text.primary;

  return createStyles({
    root: {
      boxSizing: 'border-box',

      '&.btn-Block': {
        minHeight: Size.TOUCH,
        fontWeight: 500,
        lineHeight: 0.9,
      },

      '&:not(.btn-Text)': {
        padding: '2ex 2ch 1.8ex',
        fontSize: '1.125rem',
      },

      '& svg': {
        width: 14,
        height: 14,
      },

      '.bkg-Dark &.btn-Text': {
        position: 'relative',

        '& span': {
          zIndex: 2,
        },

        '&::before': {
          boxSizing: 'content-box',
          content: '""',
          display: 'block',
          position: 'absolute',
          width: '100%',
          height: '100%',
          padding: '0 4px',
          background: 'transparent',
          zIndex: 1,
        },

        '&:hover, &:focus': {
          color: darkText,
          textDecoration: 'none',

          '&::before': {
            background: white,
          },
        },
      },
    },

    // Material common variants
    contained: {
      boxShadow: 'none',

      '&:hover, &:focus': {
        boxShadow: Shadow.TWO,
      },
    },
    disabled: {
      background: disabled,
      opacity: 1,
      pointerEvents: 'none',
    },
    outlined: {
      borderWidth: 2,

      '&:hover, &:focus': {
        borderWidth: 2,
        boxShadow: Shadow.TWO,
      },
    },
    text: {
      padding: 0,
      textAlign: 'inherit',

      '&.btn-Block': {
        fontSize: '1rem',
      },

      '&.btn-Inline': {
        minWidth: 'unset',
        font: 'inherit',
        verticalAlign: 'unset',
        display: 'inline-block',
      },

      '&:hover, &:focus': {
        background: 'none',
        textDecoration: 'underline',
      },
    },
    endIcon: {
      margin: '0 1px 1px 4px',
    },
    startIcon: {
      margin: '0 4px 1px 1px',
    },

    // Contentful field overrides
    primary: {
      '&:hover, &:focus': {
        background: primary,
      },
    },
    secondary: {
      borderColor: primary,

      '&:hover, &:focus': {
        background: 'none',
      },
    },
    emphasis: {
      background: emphasis,
      color: text,

      '&:hover, &:focus': {
        background: emphasis,
      },
    },
    back: {
      background: back,
      color: text,
    },
    outline: {
      background: 'none',
      color: text,
      borderColor: text,

      '&:hover, &:focus': {
        background: 'none',
        borderColor: text,
      },
    },
    pill: {
      background: text,
      borderRadius: '60px',
      boxShadow: Shadow.ONE,

      '&:hover, &:focus': {
        background: text,
        boxShadow: Shadow.THREE,
      },
    },
  });
});

export default useStyles;
