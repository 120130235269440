/** @jsx jsx */
import { jsx, css } from '@emotion/core';

import React, { FunctionComponent, useState, Fragment } from 'react';
import AppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import MenuIcon from '@material-ui/icons/Menu';
import { navigate } from 'gatsby';
import Grid from '@material-ui/core/Grid';

import { useTranslation } from '../../hooks/useTranslation';
import useAuth from '../../hooks/useAuth';
import ROUTES from '../../routes';
import { usePromotionContext } from '../../providers/PromotionProvider';
import colors from '../../themes/main-colors';
import MobileNavigationMenu from './MobileNavMenu';
import { useNavigationTranslations } from '../../queries/translations/navigationTranslations.query';
import PGELogo from '../logos/PGELogo';
import { NavigationUser } from '../navigation/NavigationUser';
import { ColorName } from '../../constants';
import PGEButton from '../buttons/PGE-Button';
import { useIsMobile } from '../../util/style-utils';
import LanguagePreferencePopup from '../language-preference-popup/LanguagePreferencePopup';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    toolBar: (props: { isOutagesWebPage: Boolean }) => ({
      minHeight: props.isOutagesWebPage ? 64 : 88,
      background: '#3D5265',
      opacity: 0.9,
      display: 'flex',
    }),
    promotionToolBar: (props: { isOutagesWebPage: Boolean }) => ({
      minHeight: props.isOutagesWebPage ? 64 : 88,
      background: ColorName.Grey,
      opacity: 0.9,
      display: 'flex',
      '& .text-right': {
        textAlign: 'right',
      },
    }),
    menuButton: {
      marginRight: theme.spacing(0),
    },
    title: {
      flexGrow: 1,
      fontWeight: 700,
      fontSize: '1.125rem',
    },
    paper: {
      background: colors.navBarBackground,
      color: colors.white,
    },
    logoButton: {
      padding: '5px 21px 5px 2px',
    },
    logo: (props: { isOutagesWebPage: Boolean }) => ({
      height: props.isOutagesWebPage ? 54 : 60,
      width: props.isOutagesWebPage ? 54 : 60,
      '& .angles': {
        fill: 'white',
      },
      '& .letters': {
        fill: 'white',
      },
    }),
    alignItem: {
      display: 'flex',
      alignItems: 'center',
    },
    menuIcon: {
      width: '2rem',
      height: '2rem',
    },
  }),
);

interface MobileHeaderProps {
  title: string;
}

const MobileHeader: FunctionComponent<MobileHeaderProps> = props => {
  const { navigation } = useNavigationTranslations();
  const isBrowser = !(typeof window === 'undefined');
  const isMobile = useIsMobile();
  const isOutagesWebPage =
    !isMobile &&
    isBrowser &&
    window.location.pathname.replace(/\/$/, '') === ROUTES.OUTAGES;
  const classes = useStyles({ isOutagesWebPage });
  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const { signOut, isAuthenticated } = useAuth();
  const { t } = useTranslation();
  const { promotion, noLogo, noSignIn } = usePromotionContext();

  const toggleMenu = () => {
    setMenuOpen(false);
  };

  const navigateToRoute = (route: string) => (e: any) => {
    e.preventDefault();
    setMenuOpen(false);
    return navigate(route);
  };

  return (
    <Fragment>
      <div className={classes.root} data-swiftype-index="false">
        {!promotion && (
          <Fragment>
            <AppBar
              position={'static'}
              elevation={0}
              css={{ backgroundColor: colors.noirBlur, padding: '0.25rem' }}
            >
              <Toolbar className={classes.toolBar}>
                <IconButton
                  id="hamburger-menu-icon"
                  edge="start"
                  className={classes.menuButton}
                  color="inherit"
                  aria-label="menu"
                  onClick={() => {
                    setMenuOpen(true);
                  }}
                >
                  <MenuIcon className={classes.menuIcon} />
                </IconButton>
                <Typography variant="h6" className={classes.title}>
                  {props.title}
                </Typography>
                <IconButton
                  edge="end"
                  aria-label="home"
                  className={isOutagesWebPage ? classes.logoButton : ''}
                  onClick={() => navigate('/')}
                >
                  <PGELogo
                    viewBox="0 0 108 108"
                    color="#fff"
                    className={classes.logo}
                  />
                </IconButton>
              </Toolbar>
            </AppBar>
            <MobileNavigationMenu
              menuOpen={menuOpen}
              toggleMenu={toggleMenu}
              navigateToRoute={navigateToRoute}
              navigation={navigation}
              closeModal={() => {
                setMenuOpen(false);
              }}
            />
          </Fragment>
        )}
        {promotion && (
          <AppBar
            position="static"
            elevation={0}
            color="transparent"
            css={{ backgroundColor: colors.noirBlur, padding: '0.25rem' }}
          >
            <Toolbar className={classes.promotionToolBar}>
              <Grid item xs={6}>
                {!isAuthenticated && !noSignIn && (
                  <PGEButton
                    data-testid={'sign-in-route-button'}
                    variant={'outlined'}
                    color={'secondary'}
                    onClick={() => navigate(ROUTES.SIGN_IN)}
                  >
                    {t('SIGN_IN')}
                  </PGEButton>
                )}
                {isAuthenticated && !noSignIn && (
                  <NavigationUser signOut={signOut} />
                )}
              </Grid>
              <Grid item xs={6} className="text-right">
                {!noLogo && (
                  <IconButton
                    edge="end"
                    aria-label="home"
                    className={isOutagesWebPage ? classes.logoButton : ''}
                    onClick={() => navigate('/')}
                  >
                    <PGELogo
                      viewBox="0 0 108 108"
                      color="#006DBA"
                      className={classes.logo}
                    />
                  </IconButton>
                )}
              </Grid>
            </Toolbar>
          </AppBar>
        )}
      </div>

      {isAuthenticated ? <LanguagePreferencePopup /> : null}
    </Fragment>
  );
};

export default MobileHeader;
